<!-- TODO_REMOVE: Удалить после редизайна -->
<template>
	<ul class="breadcrumbs old">
		<li class="breadcrumbs__item old">
			<router-link to="/">Вкусно и быстро</router-link>
		</li>
		<li
			class="breadcrumbs__item old"
			v-for="item of linkArr"
		>
			<a :href="`/application${item.link}`">{{ item.name }}</a>
		</li>
	</ul>
</template>

<script>
export default {
	props: ['linkArr'],
}
</script>

<style lang="scss">
.breadcrumbs.old
{
	display: flex;
	padding-left: 4px;
}

.breadcrumbs__item.old
{
	font-size: 12px;
	line-height: 15px;
	margin-right: 8px;
	list-style: none;

	a
	{color: rgba($greyLightText, .7);}

	&:not(:last-child):after
	{
		padding-left: 8px;
		content: '>';
		display: inline-block;
		color: rgba($greyLightText, .7);
	}
}
</style>